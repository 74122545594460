import onlineBookingTypes from './booking.types';

export const getAllOnlineBookingActions = (payload, cb, cbError) => ({
  type: onlineBookingTypes.GET_ALL_ONLINE_BOOKING,
  payload,
  cb,
  cbError
});

export const getOnlineBookingDetailsActions = (payload, cb, cbError) => ({
  type: onlineBookingTypes.GET_ONLINE_BOOKING_DETAILS,
  payload,
  cb,
  cbError
});

export const updateBookingStatusActions = (payload, cb, cbError) => ({
  type: onlineBookingTypes.UPDATE_BOOKING_STATUS,
  payload,
  cb,
  cbError
});
