/* eslint-disable react/prop-types */
import React from 'react';
import Select from 'components/Select';
import Input from 'components/FormInput/FormInput';
import ReactSelect from 'components/Select/ReactSelect';
import { FaRegQuestionCircle } from 'react-icons/fa';
import { HiCheckCircle } from 'react-icons/hi';
import styles from '../../websiteManagement.module.scss';
import AddTest from 'assets/icons/add-test.svg';
import { toast } from 'react-toastify';

const FormFields = ({
  formik,
  procedureType,
  setProcedureType,
  handleAddToCategory,
  selectedItems,
  allPackages,
  allProcedures,
  selectedItem,
  setSelectedItem,
  displayName,
  setDisplayName,
  packageDescription,
  setPackageDescription,
  handleRemove
}) => {
  const isSelected = (selectedItems, item) => {
    return selectedItems?.some((selected) => selected.value === item.value);
  };

  const filterOptions = (allOptions, selectedItems) => {
    return allOptions.filter(
      (option) => !isSelected(selectedItems, { value: option.id || option.unique_id })
    );
  };

  const filteredPackages = filterOptions(allPackages, selectedItems);
  const filteredProcedures = filterOptions(allProcedures, selectedItems);

  const categoryLocations = [
    { name: 'Homepage (blue frame)', id: 'Homepage (blue frame)' },
    { name: 'Centres page', id: 'Centres page' },
    { name: 'Packages page', id: 'Packages page' }
  ];

  return (
    <>
      <div className={styles.create__form__input}>
        <Select
          label="Category location"
          placeholder={formik.values.show_in}
          options={categoryLocations}
          name="show_in"
          disabled
        />
        {formik.errors.show_in && <span className="error">{formik.errors.show_in}</span>}
      </div>
      <div className={styles.create__form__input}>
        <Input
          label="Category title"
          type="text"
          value={formik.values.name}
          onChange={formik.handleChange}
          name="name"
        />
        {formik.errors.name && <span className="error">{formik.errors.name}</span>}
      </div>
      {formik.values.show_in === 'Packages page' && (
        <div className={styles.create__textarea}>
          <p>Category description</p>
          <textarea
            name="description"
            value={formik.values.description}
            onChange={formik.handleChange}
          />
          {formik.errors.description && <span className="error">{formik.errors.description}</span>}
        </div>
      )}
      <div className={styles.create__procedureTypes}>
        <div>
          <p>Type of procedure</p>
          <select
            onChange={(e) => {
              setProcedureType(e.target.value);
              setDisplayName('');
              setSelectedItem([]);
              formik.setFieldValue('add', e.target.value);
            }}
            name="add"
          >
            <option value="" disabled>
              Select...
            </option>
            <option value="packages">Package</option>
            {formik.values.show_in === 'Homepage (blue frame)' && (
              <option value="tests">Tests</option>
            )}
          </select>
        </div>
        <FaRegQuestionCircle />
      </div>
      {procedureType === 'packages' && (
        <div className={styles.create__form__input}>
          <ReactSelect
            label="Select package"
            options={filteredPackages.map((pkg) => ({
              label: pkg.name,
              value: pkg.id,
              public_name: pkg.public_name
            }))}
            value={selectedItem}
            onChange={(e) => {
              setSelectedItem(e);
              setDisplayName(e?.public_name === null ? '' : e?.public_name);
            }}
          />
        </div>
      )}
      {procedureType === 'tests' && (
        <div className={styles.create__form__input}>
          <ReactSelect
            label="Select procedure"
            options={filteredProcedures.map((proc) => ({
              label: proc.name,
              value: proc.unique_id,
              procedure_name: proc.procedure_name
            }))}
            value={selectedItem}
            onChange={(e) => {
              setSelectedItem(e);
              setDisplayName(e?.procedure_name === null ? '' : e?.procedure_name);
            }}
          />
        </div>
      )}
      <div className={styles.create__form__input}>
        <Input
          label="Public name"
          value={displayName}
          onChange={(e) => setDisplayName(e.target.value)}
        />
      </div>
      {procedureType === 'packages' && formik.values.show_in !== 'Homepage (blue frame)' && (
        <div className={styles.create__textarea} style={{ marginBottom: '12px' }}>
          <p>Package description</p>
          <textarea
            onChange={(e) => setPackageDescription(e.target.value)}
            value={packageDescription}
          />
          {packageDescription.length >= 101 && (
            <p className="error">Description cannot exceed 100 characters.</p>
          )}
        </div>
      )}
      <div
        className={styles.create__tag}
        onClick={
          displayName?.length
            ? handleAddToCategory
            : () => {
                toast.error('Please fill all required fields.');
              }
        }
      >
        <p>Add to category</p>
        <HiCheckCircle />
      </div>
      {selectedItems?.length ? (
        <div className={styles.create__selected}>
          {selectedItems?.map((item, index) => (
            <div key={index}>
              <span>
                <p className={styles.create__label}>{item.label}</p>
                <p className={styles.create__selected}>{item.public_name}</p>
              </span>
              <img src={AddTest} alt="add-test" onClick={() => handleRemove(item)} />
            </div>
          ))}
        </div>
      ) : null}
    </>
  );
};

export default FormFields;
