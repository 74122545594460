import { call, takeEvery } from 'redux-saga/effects';
import websiteManagementTypes from './websiteManagement.types';
import api from 'services/api';
import queryFormatter from 'helpers/queryFormatter';

export function* createWebHomeCategory(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() => api.post(`web/package-category`, data));
    if (res.status === 201) {
      cb(res);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* createWebHomeSubCategory(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() => api.post(`web/package-subcategory`, data));
    if (res.status === 201) {
      cb(res);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* addWebsiteProcedures(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() => api.post(`web/add-procedures`, data));
    if (res.status === 201) {
      cb(res);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* curatePackages(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() => api.patch(`web/add-curated-package`, data));
    if (res.status === 200) {
      cb(res);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* deleteSubCategoryProcedure(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() => api.delete(`web/remove-procedure`, { data }));
    if (res.status === 200) {
      cb(res);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* fetchCuratedPackages(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() => api.get(`web/curated-packages?${queryFormatter(data.params)}`));
    if (res.status === 200) {
      cb(res?.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* fetchAllWebsiteCategories(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() => api.get(`web/all-categories?${queryFormatter(data.params)}`));
    if (res.status === 200) {
      cb(res?.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* fetchWebsiteCategoryDetails(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() => api.get(`web/${data?.id}/category-details`));
    if (res.status === 200) {
      cb(res?.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* fetchWebsiteSubCategoryDetails(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() => api.get(`web/${data?.id}/subcategory-details`));
    if (res.status === 200) {
      cb(res?.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* deleteWebsiteCategoryOrSubCategory(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() => api.delete(`web/delete-category`, { data }));
    if (res.status === 200) {
      cb(res?.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* editWebsiteCategories(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() => api.post(`web/edit-category`, data));
    if (res.status === 200) {
      cb(res);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

function* watchWebsiteManagementSaga() {
  yield takeEvery(websiteManagementTypes.CREATE_HOMEPAGE_CATEGORIES, createWebHomeCategory);
  yield takeEvery(websiteManagementTypes.CREATE_HOMEPAGE_SUBCATEGORIES, createWebHomeSubCategory);
  yield takeEvery(websiteManagementTypes.ADD_WEBSITE_PROCEDURES, addWebsiteProcedures);
  yield takeEvery(websiteManagementTypes.CURATE_PACKAGES, curatePackages);
  yield takeEvery(websiteManagementTypes.DELETE_SUBCATEGORY_PROCEDURE, deleteSubCategoryProcedure);
  yield takeEvery(websiteManagementTypes.FETCH_CURATED_PACKAGES, fetchCuratedPackages);
  yield takeEvery(websiteManagementTypes.FETCH_ALL_CATEGORIES, fetchAllWebsiteCategories);
  yield takeEvery(websiteManagementTypes.FETCH_CATEGORY_DETAILS, fetchWebsiteCategoryDetails);
  yield takeEvery(websiteManagementTypes.FETCH_SUBCATEGORY_DETAILS, fetchWebsiteSubCategoryDetails);
  yield takeEvery(
    websiteManagementTypes.DELETE_CATEGORY_OR_SUBCATEGORY,
    deleteWebsiteCategoryOrSubCategory
  );
  yield takeEvery(websiteManagementTypes.EDIT_WEBSITE_CATEGORIES, editWebsiteCategories);
}

export default watchWebsiteManagementSaga;
