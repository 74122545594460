/* eslint-disable react/prop-types */
import Modal from 'components/Modal/Modal';
import React, { useEffect, useState } from 'react';
import styles from '../websiteManagement.module.scss';
import { useDispatch } from 'react-redux';
import { fetchWebsiteCategoryDetailsAction } from 're-ducks/websiteManagement';
import { getUngroupedTestAction } from 're-ducks/procedure';
import { getAllPackagesAction } from 're-ducks/packages';
import { toast } from 'react-toastify';
import Loader from 'components/Loader';
import CloseIcon from 'assets/icons/close.svg';

const ViewCategoryModal = ({ isOpen, isClose, currentItem }) => {
  const [data, setData] = useState();
  const dispatch = useDispatch();
  const [allProcedures, setAllProcedures] = useState([]);
  const [allPackages, setAllPackages] = useState([]);
  const [selectedPackages, setSelectedPackages] = useState([]);
  const [selectedProcedures, setSelectedProcedures] = useState([]);
  const [loading, setLoading] = useState(false);

  const categoryType = () => {
    switch (data?.show_in) {
      case 'Centres page':
        return (
          <div className={styles.single_category__type__centers} style={{ padding: '8px' }}>
            Centres page
          </div>
        );
      case 'Homepage (blue frame)':
        return (
          <div className={styles.single_category__type_p} style={{ padding: '8px' }}>
            Homepage (blue frame)
          </div>
        );
      default:
        return (
          <div className={styles.single_category__type__package} style={{ padding: '8px' }}>
            Packages page
          </div>
        );
    }
  };

  useEffect(() => {
    dispatch(
      getUngroupedTestAction(
        {},
        (res) => {
          setAllProcedures(res?.data);
        },
        () => {}
      )
    );
    dispatch(
      getAllPackagesAction(
        { params: { type: 'Self Pay' } },
        (res) => {
          setAllPackages(res?.packages);
        },
        (err) => {
          toast.error(err);
        }
      )
    );
  }, []);

  const fetchWebsiteCategoryDetails = () => {
    setLoading(true);
    dispatch(
      fetchWebsiteCategoryDetailsAction(
        {
          id: currentItem?.id
        },
        (res) => {
          setData(res.data);
          setLoading(false);
        },
        (err) => {
          toast.error(err);
          setLoading(false);
        }
      )
    );
  };

  useEffect(() => {
    fetchWebsiteCategoryDetails();
  }, []);

  useEffect(() => {
    if (allPackages) {
      const result = Object.values(allPackages)
        ?.map((item) => {
          const matchedCode = data?.packages?.find((check) => check.package_id === item.id);
          if (matchedCode) {
            return {
              ...item,
              ...matchedCode
            };
          }
        })
        .filter(Boolean);
      setSelectedPackages(result);
    }
    if (allProcedures) {
      const result = Object.values(allProcedures)
        ?.map((item) => {
          const matchedCode = data?.procedures?.find(
            (check) => check.procedure_code === item.unique_id
          );
          if (matchedCode) {
            return {
              ...item,
              ...matchedCode
            };
          }
        })
        .filter(Boolean);
      setSelectedProcedures(result);
    }
  }, [allPackages, allProcedures, data]);

  return (
    <div>
      <Modal isOpen={isOpen} isClose={isClose} width={616} radius={16}>
        <div className={styles.viewCategory__close}>
          <img src={CloseIcon} from alt="close" onClick={isClose} />
        </div>
        {loading ? (
          <Loader color="black" />
        ) : (
          <div className={styles.viewCategory__container}>
            <div>
              <h2 className={styles.viewCategory__caption}>Category name</h2>
              <p className={styles.viewCategory__title}>{data?.name}</p>
            </div>
            <div>
              <h2 className={styles.viewCategory__caption}>Category location</h2>
              <p style={{ margin: '0 0 16px 0', width: 'fit-content' }}>{categoryType()}</p>
            </div>
            {data?.show_in === 'Packages page' ? (
              <div>
                <h2 className={styles.viewCategory__caption}>Category description</h2>
                <p className={styles.viewCategory__description}>{data?.description || '-'}</p>
              </div>
            ) : null}
            {data?.packages?.length ? (
              <div>
                <h2 className={styles.viewCategory__caption} style={{ marginTop: '48px' }}>
                  Packages
                </h2>
                <div className={styles.create__selected}>
                  {loading ? (
                    <Loader />
                  ) : (
                    selectedPackages?.map((item, index) => (
                      <div key={index}>
                        <span>
                          <p className={styles.create__label}>{item.name}</p>
                          <p className={styles.create__selected}>{item.public_name}</p>
                        </span>
                      </div>
                    ))
                  )}
                </div>
              </div>
            ) : null}
            {data?.show_in === 'Homepage (blue frame)' && data?.procedures?.length ? (
              <div>
                <h2 className={styles.viewCategory__caption} style={{ marginTop: '48px' }}>
                  Procedures
                </h2>
                <div className={styles.create__selected}>
                  {loading ? (
                    <Loader />
                  ) : (
                    selectedProcedures?.map((item, index) => (
                      <div key={index}>
                        <span>
                          <p className={styles.create__label}>{item.name}</p>
                          <p className={styles.create__selected}>{item.public_name}</p>
                        </span>
                      </div>
                    ))
                  )}
                </div>
              </div>
            ) : null}
          </div>
        )}
      </Modal>
    </div>
  );
};

export default ViewCategoryModal;
