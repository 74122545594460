import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import Input from 'components/FormInput/FormInput';
import Select from 'components/Select';
import ReactSelect from 'components/Select/ReactSelect';
import { HiCheckCircle } from 'react-icons/hi';
import Button from 'components/Button/Button';
import styles from '../../websiteManagement.module.scss';
import { FaRegQuestionCircle } from 'react-icons/fa';
import AddTest from 'assets/icons/add-test.svg';
import { useDispatch } from 'react-redux';
import { getUngroupedTestAction } from 're-ducks/procedure';
import { getAllPackagesAction } from 're-ducks/packages';
import { toast } from 'react-toastify';
import { createWebHomePackagesAction } from 're-ducks/websiteManagement';
import { removeEmpty } from 'utils/removeEmptyValues';
import { useNavigate } from 'react-router-dom';
import { CreateCategoryFormSchema } from 'utils/validation';

const CreateCategoryForm = () => {
  const dispatch = useDispatch();
  const [procedureType, setProcedureType] = useState('packages');
  const [allProcedures, setAllProcedures] = useState([]);
  const [allPackages, setAllPackages] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]); // This holds the items added to the array
  const [selectedItem, setSelectedItem] = useState(null); // This holds the currently selected item before adding to the array
  const [selectedCategoryLocation, setSelectedCategoryLocation] = useState({});
  const [displayName, setDisplayName] = useState('');
  const [packageDescription, setPackageDescription] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const categoryLocations = [
    { name: 'Homepage (blue frame)', id: 'Homepage (blue frame)' },
    { name: 'Centres page', id: 'Centres page' },
    { name: 'Packages page', id: 'Packages page' }
  ];

  useEffect(() => {
    dispatch(
      getUngroupedTestAction(
        {},
        (res) => {
          setAllProcedures(res?.data);
        },
        () => {}
      )
    );
    dispatch(
      getAllPackagesAction(
        { params: { type: 'Self Pay' } },
        (res) => {
          setAllPackages(res?.packages);
        },
        (err) => {
          toast.error(err);
        }
      )
    );
  }, []);

  const handleRemove = (item) => {
    setSelectedItems(selectedItems?.filter((procedure) => procedure?.value !== item?.value));
  };

  const handleAddToCategory = () => {
    if (selectedItem) {
      const newEntry = {
        ...selectedItem,
        public_name: displayName,
        description: packageDescription
      };

      setSelectedItems([...selectedItems, newEntry]);
      setDisplayName(''); // Clear display name
      setPackageDescription('');
      setSelectedItem(null); // Clear the selected item
    }
  };

  const isSelected = (item) => {
    return selectedItems.some((selected) => selected.value === item.value);
  };

  const filteredPackages = allPackages.filter((pkg) => !isSelected({ value: pkg.id }));
  const filteredProcedures = allProcedures.filter((proc) => !isSelected({ value: proc.unique_id }));

  const handleCreateCategory = (values) => {
    const payload = {
      ...values,
      packages:
        values?.add === 'packages'
          ? selectedItems?.map((item) => ({
              package_id: item.value,
              public_name: item.public_name,
              description: item.description
            }))
          : '',
      procedures:
        values?.add === 'tests'
          ? selectedItems?.map((item) => ({
              procedure_code: item.value,
              public_name: item.public_name
            }))
          : ''
    };
    setLoading(true);
    dispatch(
      createWebHomePackagesAction(
        removeEmpty(payload),
        (res) => {
          setLoading(false);
          toast.success(res.data.message);
          navigate('/website-management');
        },
        (err) => {
          toast.error(err);
          setLoading(false);
        }
      )
    );
  };

  return (
    <Formik
      initialValues={{
        name: '',
        show_in: '',
        description: '',
        add: 'packages'
      }}
      onSubmit={handleCreateCategory}
      validationSchema={CreateCategoryFormSchema}
    >
      {(formik) => (
        <div className={styles.create__form}>
          <Form>
            <div className={styles.create__form__input}>
              <Select
                label="Category location"
                placeholder="Select..."
                options={categoryLocations}
                selected={selectedCategoryLocation}
                name="show_in"
                handleChange={(e) => {
                  formik.setFieldValue('show_in', e.name);
                  setSelectedCategoryLocation(e);
                  setProcedureType('packages');
                }}
              />
              {formik.errors.show_in ? (
                <span className="error">{formik.errors.show_in}</span>
              ) : null}
            </div>
            <div className={styles.create__form__input}>
              <Input
                label="Category title"
                type="text"
                value={formik.values.name}
                onChange={formik.handleChange}
                name="name"
              />
              {formik.errors.name ? <span className="error">{formik.errors.name}</span> : null}
            </div>
            {formik.values.show_in === 'Packages page' && (
              <div className={styles.create__textarea}>
                <p>Category description</p>
                <textarea
                  name="description"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                />
                {formik.errors.description ? (
                  <span className="error">{formik.errors.description}</span>
                ) : null}
              </div>
            )}
            <div className={styles.create__procedureTypes}>
              <div>
                <p>Type of procedure</p>
                <select
                  onChange={(e) => {
                    setProcedureType(e.target.value);
                    formik.setFieldValue('add', e.target.value);
                    setSelectedItems([]); // Clear selected items on type change
                  }}
                  name="add"
                >
                  <option value="" disabled>
                    Select...
                  </option>
                  <option value="packages">Package</option>
                  {formik.values.show_in === 'Homepage (blue frame)' && (
                    <option value="tests">Tests</option>
                  )}
                </select>
              </div>
              <FaRegQuestionCircle />
            </div>
            {procedureType === 'packages' && (
              <div className={styles.create__form__input}>
                <ReactSelect
                  label="Select package"
                  options={filteredPackages?.map((i) => ({
                    label: i.name,
                    value: i.id,
                    public_name: i.public_name
                  }))}
                  value={selectedItem}
                  onChange={(e) => {
                    setSelectedItem(e); // Set the selected item
                    setDisplayName(e.public_name === null ? '' : e.public_name);
                  }}
                />
              </div>
            )}
            {procedureType === 'tests' && (
              <div className={styles.create__form__input}>
                <ReactSelect
                  label="Select procedure"
                  options={filteredProcedures?.map((i) => ({
                    label: i.name,
                    value: i.unique_id,
                    procedure_name: i.procedure_name
                  }))}
                  value={selectedItem}
                  onChange={(e) => {
                    setSelectedItem(e); // Set the selected item
                    setDisplayName(e?.procedure_name === null ? '' : e?.procedure_name);
                  }}
                />
              </div>
            )}
            <div className={styles.create__form__input}>
              <Input
                label="Public name"
                value={displayName}
                onChange={(e) => setDisplayName(e.target.value)}
              />
            </div>
            {procedureType === 'packages' && formik.values.show_in !== 'Homepage (blue frame)' && (
              <div className={styles.create__textarea} style={{ marginBottom: '12px' }}>
                <p>Package description</p>
                <textarea
                  onChange={(e) => setPackageDescription(e.target.value)}
                  value={packageDescription}
                />
                {packageDescription.length >= 101 && (
                  <p className="error">Description cannot exceed 100 characters.</p>
                )}
              </div>
            )}
            <div
              className={styles.create__tag}
              onClick={
                displayName?.length
                  ? handleAddToCategory
                  : () => {
                      toast.error('Please fill all required fields.');
                    }
              }
            >
              <p>Add to category</p>
              <HiCheckCircle />
            </div>
            {selectedItems?.length ? (
              <div className={styles.create__selected}>
                {selectedItems?.map((item, index) => (
                  <div key={index}>
                    <span>
                      <p className={styles.create__label}>{item.label}</p>
                      <p className={styles.create__selected}>{item.public_name}</p>
                    </span>
                    <img src={AddTest} alt="add-test" onClick={() => handleRemove(item)} />
                  </div>
                ))}
              </div>
            ) : null}
            <Button
              theme="primary"
              size="md"
              type="submit"
              disabled={!formik.isValid || !formik.dirty || loading || selectedItems?.length === 0}
              loading={loading}
            >
              Save category
            </Button>
          </Form>
        </div>
      )}
    </Formik>
  );
};

export default CreateCategoryForm;
