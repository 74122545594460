import { API } from 'const';
import { put, takeEvery, call } from 'redux-saga/effects';
import queryFormatter from 'helpers/queryFormatter';
import api from 'services/api';
import onlineBookingTypes from './booking.types';

export function* getAllOnlineBooking(action) {
  try {
    const { payload: data, cb } = action;
    const res = yield call(() => api.get(`${API.ONLINEBOOKING}?${queryFormatter(data.params)}`));
    if (res.status === 200) {
      yield put({ type: onlineBookingTypes.SET_ALL_ONLINE_BOOKING, payload: res.data.data });

      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

function* getOnlineBookingDetails(action) {
  try {
    const { payload: data, cb } = action;
    const res = yield call(() => api.get(`${API.ONLINEBOOKINGDETAILS}/${data.id}`, data?.data));
    if (res.status === 200) {
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

function* updateBookingStatus(action) {
  try {
    const { payload: data, cb } = action;
    const res = yield call(() => api.patch(`web/booking/update-status/${data.id}`, data?.data));
    if (res.status === 200) {
      cb(res.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

function* watchOnlineBookingsSaga() {
  yield takeEvery(onlineBookingTypes.GET_ALL_ONLINE_BOOKING, getAllOnlineBooking);
  yield takeEvery(onlineBookingTypes.GET_ONLINE_BOOKING_DETAILS, getOnlineBookingDetails);
  yield takeEvery(onlineBookingTypes.UPDATE_BOOKING_STATUS, updateBookingStatus);
}

export default watchOnlineBookingsSaga;
