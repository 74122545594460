import SingleCategory from './singleCategory';
import styles from '../websiteManagement.module.scss';
import DeleteCategory from './deleteCategory';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  deleteWebsiteCategoryOrSubCategoryAction,
  fetchAllWebsiteCategoriesAction
} from 're-ducks/websiteManagement';
import SinglePageLoader from 'components/Loading/SinglePageLoader';
import EmptyState from '../emptyState';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import Pagination from 'components/Pagination/Pagination';
import getTotalPages from 'helpers/getTotalPages';
import SearchBox from 'components/SearchBox/SearchBox';
import { useDebounce } from 'use-debounce';
import FilterIcon from 'assets/icons/filterIcon.svg';
import Button from 'components/Button/Button';
import Cancel from 'assets/icons/close.svg';
import FilterHomePackages from './filterHomePackages';
import { useNavigate } from 'react-router-dom';
import Plus from 'assets/icons/Plus.svg';
import ViewCategoryModal from '../packageManagement/viewCategoryModal';

const HomePagePackages = ({ setCount, page, setPage }) => {
  const [deleteModal, setDeleteModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [allCategories, setAllCategories] = useState([]);
  const [currentItem, setCurrentItem] = useState({});
  const [searchValue, setSearchValue] = useState('');
  const [searchDebounce] = useDebounce(searchValue, 300);
  const [openFilter, setOpenFilter] = useState(false);
  const [filteredData, setFilteredData] = useState({});
  const [viewModal, setViewModal] = useState(false);
  const navigate = useNavigate();

  const handlePageChange = (num) => {
    setPage(num.selected + 1);
  };

  const fetchAllWebsiteCategories = () => {
    setLoading(true);
    dispatch(
      fetchAllWebsiteCategoriesAction(
        {
          params: {
            ...filteredData,
            page: page,
            per_page: 12,
            search: searchDebounce
          }
        },
        (res) => {
          setLoading(false);
          setAllCategories(res.data);
          setCount(res?.data?.total);
        },
        () => {
          setLoading(false);
        }
      )
    );
  };

  useEffect(() => {
    fetchAllWebsiteCategories();
  }, [page, searchDebounce, filteredData]);

  const deleteCategory = () => {
    setLoader(true);
    dispatch(
      deleteWebsiteCategoryOrSubCategoryAction(
        {
          delete: 'category',
          category_id: currentItem?.id
        },
        () => {
          setLoader(false);
          toast.success('Category has been deleted');
          setCurrentItem({});
          setDeleteModal(false);
          fetchAllWebsiteCategories();
        },
        (err) => {
          setLoader(false);
          toast.error(err);
          setDeleteModal(false);
        }
      )
    );
  };
  return (
    <div>
      <div className={styles.actionBox__search}>
        <SearchBox
          placeholder="Search by name"
          onChange={(e) => {
            setSearchValue(e);
            setPage(1);
          }}
          searchValue={searchValue}
        />
        <div className={styles.actionBox__btn}>
          <Button theme="secondary" size="plain">
            <div>
              {Object.keys(filteredData).length !== 0 ? (
                <div
                  className={styles.filterBtn}
                  aria-hidden
                  onClick={() => {
                    setFilteredData([]);
                    setOpenFilter(true);
                  }}
                >
                  <p>Filter</p> &nbsp;
                  <img src={Cancel} alt="cancel" />
                </div>
              ) : (
                <div className={styles.filterBtn} onClick={() => setOpenFilter(true)} aria-hidden>
                  <p>Filter</p> &nbsp;
                  <img src={FilterIcon} alt="filter" />
                </div>
              )}
            </div>
          </Button>
          <Button
            theme="primary"
            size="sm"
            onClick={() => navigate('/website-management/home-package/create')}
          >
            <img src={Plus} alt="add" />
            &nbsp; Curate a package
          </Button>
        </div>
      </div>
      {loading ? (
        <SinglePageLoader />
      ) : (
        <div>
          <div className={styles.categories}>
            {allCategories?.packageCategories?.map((item) => {
              return (
                <SingleCategory
                  onDelete={() => {
                    setCurrentItem(item);
                    setDeleteModal(true);
                  }}
                  item={item}
                  onCategoryView={() => {
                    setViewModal(true);
                    setCurrentItem(item);
                  }}
                />
              );
            })}
          </div>
        </div>
      )}
      <DeleteCategory
        isOpen={deleteModal}
        isClose={() => setDeleteModal(false)}
        onDelete={() => deleteCategory()}
        loadingBtn={loader}
      />
      {!allCategories?.packageCategories?.length && !loading && <EmptyState />}
      {allCategories?.packageCategories?.length && !loading ? (
        <Pagination
          currentPage={page}
          changeCurrentPage={handlePageChange}
          forcePage={page - 1}
          handlePageInput={(e) => setPage(e)}
          totalPage={getTotalPages(allCategories?.total, 12)}
        />
      ) : null}

      {openFilter && (
        <FilterHomePackages
          isOpen={openFilter}
          isClose={() => setOpenFilter(false)}
          setFilteredData={setFilteredData}
          setPage={setPage}
        />
      )}
      {viewModal && (
        <ViewCategoryModal
          isOpen={viewModal}
          isClose={() => setViewModal(false)}
          currentItem={currentItem}
        />
      )}
    </div>
  );
};

HomePagePackages.propTypes = {
  setCount: PropTypes.func,
  page: PropTypes.number,
  setPage: PropTypes.func,
  searchDebounce: PropTypes.any,
  searchValue: PropTypes.string
};

export default HomePagePackages;
